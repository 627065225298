import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { FraudWarningQuery } from "../../graphql-types";
import Footer from "../components/Footer";
import MenuBar from "../components/MenuBar";
import PageContent from "../components/PageContent";
import SectionHeader from "../components/SectionHeader";
import { mustBeDefined } from "../utils";

const FraudWarningPage: React.FC = () => {
    const queryResults = useStaticQuery<FraudWarningQuery>(query);
    const fraudWarning = mustBeDefined(queryResults.fraudWarning).otherwise(
      "CMS_DATA_MISSING",
      "FraudWarningQuery.fraudWarning"
    );

    const { title } = fraudWarning.edges[0].node;
    const { text } = fraudWarning.edges[0].node;
    
    return (
        <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate fraudulent schemes page" />
        <title>BAM Elevate - Fraudulent warnings</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />


        <PageContent className="pt-16">
          <SectionHeader
            title={title}
            centered
          />
          <ReactMarkdown 
            children={text?.data?.childMarkdownRemark?.rawMarkdownBody} 
            className="p-4 md:p-10 prose"
    				rehypePlugins={[rehypeRaw]} 
          />
        </PageContent>
        <PageContent>
          <Footer />
        </PageContent>
      </main>
    </>
    )
}

 const query = graphql`
  query FraudWarning {
    fraudWarning: allStrapiLegalDocument(filter: {title: {eq: "Fraudulent Schemes Warning"}}) {
      edges {
        node {
          text {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          title
        }
      }
    }
  }
`

export default FraudWarningPage;